import * as React from "react";
import { AddEditModalDialog, DeleteModalConstants, EngagementTypeNotification, GeneralSettingConstants, PageLoaderKeys } from "helper/Constants";
import { Button, Form } from "react-bootstrap";
import AutomaticReminders from "./AutomaticReminders";
import "../../settings/style.scss";
import CustomToggleSwitch from "common/components/switch/CustomToggleSwitch";
import UploadPercentageSection from "./UploadPercentageSection";
import { useEffect, useState } from "react";
import { getGatherCompanySettingsModel, putGatherCompanySettingsModel } from "store/services/companySettings-service";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import {
  initialCompanySettings,
  setEnableAutoReminder,
  setEnableUploadNtification,
  setEnabledocumentUploadPercentage,
  setEnablek1UploadPercentage,
  setEsignReminder,
  setSourceDocumentSettingChangeFlag,
  setdocumentUploadPercentage,
  setenabledSourceDocuments,
  setk1UploadPercentage,
  setnoAdditionalCPANotificationRequired,
  setnotifyEveryTimeUploadOrDeleteSrcDoc,
  setorganizerReminder,
  setschNotification1PerDayUploadOrDeleteSrcDoc
} from "store/slices/companySettings-slice";
import { GatherCompanySettingsModel } from "common/model/company-settings";
import { AppNotifier } from "common/components/toast/Toast";
import { Prompt } from "react-router-dom";
import LoaderWrapper from "common/pages/LoaderWrapper";
import MessageListBox from "./EngagementType";
import EngagementTypeAddEditModal, { ModalType } from "./AddEditModal";
import { EngagementsType, EngagementsTypeState } from "common/model/engagements-type";
import { addEngagementsTypeapi, deleteEngagementTypesApi, editEngagementsTypeapi, getEngagementCategories, getEngagementsType } from "store/services/engagement-type-service";
import { set } from "core-js/core/dict";
import EngagementTypeListBox from "./EngagementType";
import { AddIcon } from "assets/icons/IconCollection";
import { EngagementTypeSettingsConstants, MessageSettingsConstants } from "pages/Constants";
import ConfirmDialog from "common/components/modal/ConfirmDialog";
export enum SettingsType {
  SOURCEDOC = 0,
  NOTIFY,
  NOTIFY2,
  CPA_NOTIFICATION,
  UPLOAD_NOTIFICATION,
  ENABLE_AUTOREMINDER,
  SOURCE_DOC_UPLOAD_NOTIFICATION,
  SOURCE_DOC_UPLOAD_PERCENTAGE,
  K1_DOC_UPLOAD_NOTIFICATION,
  K1_DOC_UPLOAD_PERCENTAGE,
  ORG_REMINDER,
  ESIGN_REMINDER
}
const General: React.FC = () => {
  const dispatch = useAppDispatch();
  const companySetting: GatherCompanySettingsModel = useAppSelector((state) => state.companySetting);
  const EngagementsTypes: EngagementsTypeState = useAppSelector((state) => state.engagementsTypeReducer);
  const [k1DocumentUploadNotification, setK1DocumentUploadNotification] = useState<boolean>(
    companySetting.sourceDocumentSettings.enabledK1UploadPercentageNotification
  );
  const [seletedEngagement, setseletedEngagement] = useState<EngagementsType>({ id: 0, isSystemDefault: false, isUserDefault: false, name: "" });
  const [showAddEditModal, setshowAddEditModal] = useState(false);
  const [modalType, setmodalType] = useState<ModalType>(ModalType.NONE)
  const [showPrompt, setShowPrompt] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);
  const [sourceDocumentUploadNotification, setSourceDocumentUploadNotification] = useState<boolean>(
    companySetting.sourceDocumentSettings.enabledDocumentUploadPercentageNotification
  );

  const settingsRef: React.MutableRefObject<GatherCompanySettingsModel> =
    React.useRef<GatherCompanySettingsModel>(initialCompanySettings);

  useEffect(() => {
    dispatch(getGatherCompanySettingsModel(storeToRef))
    dispatch(getEngagementsType())
    dispatch(getEngagementCategories())
  }, []);
  useEffect(() => { setShowPrompt(isDataChanged(companySetting)) }, [companySetting]);
  const storeToRef = (data: GatherCompanySettingsModel) => {
    settingsRef.current = { ...data, sourceDocumentSettingChangeFlag: false };
  };
  function sourceDocumentUpdate(state: boolean) {
    if (companySetting.sourceDocumentSettingChangeFlag) {
      AppNotifier.Warning(GeneralSettingConstants.SourceDocumentNotificationDefault);
      dispatch(setSourceDocumentSettingChangeFlag(false));
    } else {
      dispatch(setSourceDocumentSettingChangeFlag(true));
    }
    dispatch(setenabledSourceDocuments(state));
  }
  const callBackfun = () => {
    dispatch(getEngagementsType())
  }
  function applyCompanysetting(state: any) {
    const gatherCompanySettingsModelObject: GatherCompanySettingsModel = {
      defaultSettings: state.defaultSettings,
      authenticationSettings: state.authenticationSettings,
      sourceDocumentSettings: state.sourceDocumentSettings,
      reminderSettings: state.reminderSettings,
      sourceDocumentSettingChangeFlag: false
    };

    storeToRef({ ...gatherCompanySettingsModelObject, sourceDocumentSettingChangeFlag: false })
    dispatch(putGatherCompanySettingsModel(gatherCompanySettingsModelObject));
    dispatch(setSourceDocumentSettingChangeFlag(false));
    setShowPrompt(false)
  }

  const updateSettings = (type: SettingsType, payload?: any) => {
    switch (type) {
      case SettingsType.SOURCEDOC:
        sourceDocumentUpdate(payload);
        break;
      case SettingsType.NOTIFY:
        dispatch(setnotifyEveryTimeUploadOrDeleteSrcDoc(payload));
        break;
      case SettingsType.NOTIFY2:
        dispatch(setschNotification1PerDayUploadOrDeleteSrcDoc(payload));
        break;
      case SettingsType.CPA_NOTIFICATION:
        dispatch(setnoAdditionalCPANotificationRequired(payload));
        break;
      case SettingsType.UPLOAD_NOTIFICATION:
        dispatch(setEnableUploadNtification(payload));
        break;
      case SettingsType.ENABLE_AUTOREMINDER:
        dispatch(setEnableAutoReminder(payload));
        break;
      case SettingsType.ESIGN_REMINDER:
        dispatch(setEsignReminder(payload));
        break;
      case SettingsType.ORG_REMINDER:
        dispatch(setorganizerReminder(payload));
        break;
      case SettingsType.SOURCE_DOC_UPLOAD_NOTIFICATION:
        dispatch(setEnabledocumentUploadPercentage(payload));
        break;
      case SettingsType.K1_DOC_UPLOAD_NOTIFICATION:
        dispatch(setEnablek1UploadPercentage(payload));
        break;
      case SettingsType.SOURCE_DOC_UPLOAD_PERCENTAGE:
        dispatch(setdocumentUploadPercentage(payload));
        break;
      case SettingsType.K1_DOC_UPLOAD_PERCENTAGE:
        dispatch(setk1UploadPercentage(payload));
        break;
      default:
        break;
    }

  };
  const isDataChanged = (data: GatherCompanySettingsModel) => {
    return JSON.stringify(data) !== JSON.stringify(settingsRef.current);
  };
  const onEdit = (data: EngagementsType) => {
    setshowAddEditModal(true);
    setseletedEngagement(data);
    setmodalType(ModalType.EDIT);
  }
  const onHide = () => {
    setshowAddEditModal(false);
    setseletedEngagement({ id: 0, isSystemDefault: false, isUserDefault: false, name: "" });
    setmodalType(ModalType.NONE);
  }
  const onAddEngagementType = () => {
    if(EngagementsTypes.engagementsTypeArray.filter(e => !e.isSystemDefault).length<10)
    {
      setshowAddEditModal(true);
      setseletedEngagement({ id: 0, isSystemDefault: false, isUserDefault: false, name: "" });
      setmodalType(ModalType.ADD);
    }
    else
    AppNotifier.Error(AddEditModalDialog.ENGAGEMENT_TYPE.LIMIT_EXCEEDED)
  }
  const onSubmit = (data: EngagementsType) => {
    if (modalType === ModalType.ADD)
      dispatch(addEngagementsTypeapi(data, callBackfun))
    else
      dispatch(editEngagementsTypeapi(data, callBackfun))
    setshowAddEditModal(false)
    setmodalType(ModalType.NONE)
    setseletedEngagement({ id: 0, isSystemDefault: false, isUserDefault: false, name: "" })
  }
  const onDelete = (data: EngagementsType) => {
    setshowDeleteModal(true);
    setseletedEngagement(data);
  }
  const onDeleteEengagementType = () => {
    if(seletedEngagement.isSystemDefault)
    {
      AppNotifier.Error(EngagementTypeNotification.Failure.FailedToDeleteSystemDefault)
    }
    else if(seletedEngagement.isUserDefault)
    {
      AppNotifier.Error(EngagementTypeNotification.Failure.FailedToDeleteDefault)
    }
    else{
      dispatch(deleteEngagementTypesApi(seletedEngagement,callBackfun))
      setseletedEngagement({ id: 0, isSystemDefault: false, isUserDefault: false, name: "" })
      setshowDeleteModal(false);
    }
  }

  const onDeleteCancel = () => {
    setseletedEngagement({ id: 0, isSystemDefault: false, isUserDefault: false, name: "" })
    setshowDeleteModal(false);
  }

  return (
    <div className="settings-container">
      <LoaderWrapper keys={PageLoaderKeys.General} isScrollableView>
        <Prompt when={showPrompt} message={GeneralSettingConstants.promptMessage} />
        <header className="margin-bottom-auto">
          <h3>General</h3>
          <Button onClick={() => applyCompanysetting(companySetting)} className="button-default ss-btn-primary">
            Apply
          </Button>
        </header>
        <section>
          <h4>{GeneralSettingConstants.sourceDocumentHeading}</h4>
          <div className="settings-form-item settings-check-box-label">
            <Form.Check
              checked={companySetting.sourceDocumentSettings.enabledSourceDocuments}
              onChange={() => updateSettings(SettingsType.SOURCEDOC, companySetting.sourceDocumentSettings.enabledSourceDocuments)}
              type="checkbox"
            />
            <Form.Label>{GeneralSettingConstants.sourceDocumentChecbox}</Form.Label>
          </div>
          {companySetting.sourceDocumentSettings.enabledSourceDocuments && (
            <div>
              <div className="sub-section-item">
                <h5>{GeneralSettingConstants.sourceDocumentNotificationHeading}</h5>
                <div className="settings-form-group">
                  <div className="settings-form-item">
                    <Form.Check  
                      checked={companySetting.sourceDocumentSettings.notifyEveryTimeUploadOrDeleteSrcDoc}
                      onChange={() =>
                        updateSettings(
                          SettingsType.NOTIFY,
                          companySetting.sourceDocumentSettings.notifyEveryTimeUploadOrDeleteSrcDoc
                        )
                      }
                      type="radio"
                    />
                    <Form.Label>{GeneralSettingConstants.notifyEveryTimeUploadOrDeleteSrcDocRadioBtn}</Form.Label>
                  </div>
                  <div className="settings-form-item">
                    <Form.Check  
                      checked={companySetting.sourceDocumentSettings.schNotification1PerDayUploadOrDeleteSrcDoc}
                      onChange={() =>
                        updateSettings(
                          SettingsType.NOTIFY2,
                          companySetting.sourceDocumentSettings.schNotification1PerDayUploadOrDeleteSrcDoc
                        )
                      }
                      type="radio"
                    />
                    <Form.Label>{GeneralSettingConstants.schNotification1PerDayUploadOrDeleteSrcDocRadioBtn}</Form.Label>
                  </div>
                  <div className="settings-form-item">
                    <Form.Check  
                      checked={companySetting.sourceDocumentSettings.noAdditionalCPANotificationRequired}
                      onChange={() =>
                        updateSettings(
                          SettingsType.CPA_NOTIFICATION,
                          companySetting.sourceDocumentSettings.noAdditionalCPANotificationRequired
                        )
                      }
                      type="radio"
                    />
                    <Form.Label>{GeneralSettingConstants.sourceDocumentUploadNotificationDisabledRadioBtn}</Form.Label>
                  </div>
                </div>
              </div>
              <div className="sub-section-item">
                <h5>{GeneralSettingConstants.completionNotifications}</h5>
                <div className="settings-form-item settings-check-box-label">
                  <CustomToggleSwitch
                    switched={companySetting.sourceDocumentSettings.enabledDocumentUploadNotification}
                    label={GeneralSettingConstants.uploadNotificationLable}
                    name={"Uplodenotifaction_Toggole"}
                    handleChange={() =>
                      updateSettings(
                        SettingsType.UPLOAD_NOTIFICATION,
                        companySetting.sourceDocumentSettings.enabledDocumentUploadNotification
                      )
                    }
                  />
                </div>
                <UploadPercentageSection
                  k1DocumentUploadNotification={companySetting.sourceDocumentSettings.enabledK1UploadPercentageNotification}
                  sourceDocumentUploadNotification={companySetting.sourceDocumentSettings.enabledDocumentUploadPercentageNotification}
                  state={companySetting}
                  disableDropdown={companySetting.sourceDocumentSettings.enabledDocumentUploadNotification}
                  updateSettings={updateSettings}
                />
              </div>
            </div>
          )}
        </section>
        <section className="automatic-reminders-wrapper">
          <h4>{GeneralSettingConstants.automaticRemaindersHeading}</h4>
          <div className="settings-form-item settings-check-box-label">
            <Form.Check  
              checked={companySetting.reminderSettings.automaticReminders}
              onChange={() => updateSettings(SettingsType.ENABLE_AUTOREMINDER, companySetting.reminderSettings.automaticReminders)}
              type="checkbox"
            />
            <Form.Label>{GeneralSettingConstants.automaticRemaindersCheckbox}</Form.Label>
          </div>

          <AutomaticReminders
            state={companySetting}
            disableDropdown={!companySetting.reminderSettings.automaticReminders}
            updateSettings={updateSettings}
          />
        </section>
        <section className="engagement-type-wrapper engagemen-type-wrapper">
          <h4>{GeneralSettingConstants.engagementType}</h4>
          <EngagementTypeListBox
            data={EngagementsTypes.engagementsTypeArray}
            onEdit={onEdit}
            onDelete={onDelete}
          />
          <button onClick={onAddEngagementType} className="btn-with-icon" title={EngagementTypeSettingsConstants.AddMessage}>
            <AddIcon />
            Add
          </button>
        </section>
        <EngagementTypeAddEditModal
          show={showAddEditModal}
          onSubmit={onSubmit}
          onHide={onHide}
          modalType={modalType}
          initialData={seletedEngagement}
          data={EngagementsTypes?.engagementsTypeArray} 
          engagementCategories={EngagementsTypes?.engagementCategories}/>

        <ConfirmDialog
          title={DeleteModalConstants.EngagementType.title}
          message={DeleteModalConstants.EngagementType.message}
          additionalMessage={DeleteModalConstants.EngagementType.continueMessage}
          show={showDeleteModal}
          onHide={onDeleteCancel}
          onSubmit={onDeleteEengagementType}
          additionalMessageStyle={"delete-message-margin"}
        />
      </LoaderWrapper>
    </div>
  );
};

export default General;
