import React, { useEffect, useState } from "react";
import { CustomModal } from "common/components/UIComponents/CustomModal";
import { Alert, Form } from "react-bootstrap";
import { AddEditModalDialog } from "helper/Constants";
import VariablesList from "./VariablesList";
import { EmailTemplateMessage } from "common/model/client-instruction";
import TinyMCEComponent from "common/components/tinymce/TinyMCE";
import { AppNotifier } from "common/components/toast/Toast";

export enum ModalType {
  NONE,
  ADD,
  EDIT
}

interface IAddEditModalProps {
  show: boolean;
  isDefaultMessage: boolean;
  onSubmit: (data: EmailTemplateMessage, setDefault: boolean) => void;
  onHide: () => void;
  modalType: ModalType;
  intialData: EmailTemplateMessage;
  data: EmailTemplateMessage[];
}
export interface IValidationState {
  alert: string;
  message: string;
  showAlert: boolean;
  saving: boolean;
}

export const initialValidationMessages = {
  name: "",
  subject: "",
  body: ""
};

const AddEditModal: React.FC<IAddEditModalProps> = ({
  show,
  onHide,
  onSubmit,
  modalType,
  isDefaultMessage: defaultMsg,
  intialData,
  data
}) => {
  const [modalData, setModalData] = useState<EmailTemplateMessage>(intialData);
  const [validationMessages, setValidationMessages] = useState(initialValidationMessages);
  const [setDefaultMsg, onSetDefault] = useState(defaultMsg);
  const [isClose, setIsClose] = React.useState(true);
  const [nameValidation, setNamevalidation] = useState("");
  const [subjectValidation, setSubjectValidation] = useState("");
  const [textValiation, setTextValiation] = useState("");

  useEffect(() => {
    setModalData(intialData);
    setValidationMessages(initialValidationMessages);
    onSetDefault(defaultMsg);
    setTextValiation("");
  }, [intialData, defaultMsg, show]);

  const { isSystemDefault } = modalData;

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "name") {
      if (value) {

        setNamevalidation("")
      }
      else {
        setNamevalidation(AddEditModalDialog.CLIENT_INSTRUCTION.NAME_VALIDATION_TEXT)
      }
      setModalData({ ...modalData, [name]: value });
    }

    if (name === "subject") {
      if (value) {
        setSubjectValidation("")

      }
      else {
        setSubjectValidation(AddEditModalDialog.CLIENT_INSTRUCTION.SUBJECT_VALIDATION_TEXT)
      }
      setModalData({ ...modalData, [name]: value });
    }
  };

  const changeMessageBody = (e: any) => {
    if (e) {
      setTextValiation("")
    } else {
      setTextValiation(AddEditModalDialog.CLIENT_INSTRUCTION.BODY_VALIDATION_TEXT)
    }
    setModalData({ ...modalData, body: e });
  };

  const isModalDataValid = () => {
    let isValid = true;
    if (checkDuplicate(modalData) > 0) {
      AppNotifier.Warning(AddEditModalDialog.CLIENT_INSTRUCTION.DUPLICATE_INSTRUCTION_NAME)
      isValid = false;
    } else if (modalData.name.trim() == "") {
      setNamevalidation(AddEditModalDialog.CLIENT_INSTRUCTION.NAME_VALIDATION_TEXT)
      isValid = false;
    } else if (modalData.subject.trim() == "") {
      setSubjectValidation(AddEditModalDialog.CLIENT_INSTRUCTION.SUBJECT_VALIDATION_TEXT)
      isValid = false;
    } else if (modalData.body.trim() === "") {
      setTextValiation(AddEditModalDialog.CLIENT_INSTRUCTION.BODY_VALIDATION_TEXT)
      isValid = false;
    }
    return isValid;
  };

  const onCancel = () => {
    setSubjectValidation("");
    setTextValiation("")
    setNamevalidation("")
    setIsClose(true);
    onHide();
  };

  const checkDuplicate = (messageDetail: EmailTemplateMessage) => {
    let count: number = 0;
    const emailMessages = data;
    emailMessages != null &&
      emailMessages.map((message, index) => {
        if (message.name.trim().toLowerCase() == messageDetail.name.trim().toLowerCase() && message.id != messageDetail.id)
          count++;
      });
    return count;
  };

  const handleOnSubmit = () => {

    if (isModalDataValid()) {
      onSubmit(modalData, setDefaultMsg);
      setIsClose(true);
      setSubjectValidation("")
      setTextValiation("")
      setNamevalidation("")
    }

  };

  const setDefaultMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSetDefault(event.target.checked);
    event.target.checked === true
      ? AppNotifier.Warning(AddEditModalDialog.CLIENT_INSTRUCTION.SetDefaultMessageWarning)
      : AppNotifier.Warning(AddEditModalDialog.CLIENT_INSTRUCTION.RemoveDefaultMessageWarning);
  };

  return (
    <CustomModal
      show={show}
      onSubmit={handleOnSubmit}
      onHide={onCancel}
      title={
        modalType === ModalType.ADD
          ? AddEditModalDialog.CLIENT_INSTRUCTION.ADD_TITLE
          : AddEditModalDialog.CLIENT_INSTRUCTION.EDIT_TITLE
      }
      confirmButtonName={
        modalType === ModalType.ADD
          ? AddEditModalDialog.CLIENT_INSTRUCTION.ADD_BUTTON_TEXT
          : AddEditModalDialog.CLIENT_INSTRUCTION.UPDATE_BUTTON_TEXT
      }
      onCancelButtonClick={onCancel}
      className="add-edit-modal"
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleOnSubmit();
        }}
        validated={validationMessages.name !== "" && validationMessages.subject !== "" && validationMessages.body !== ""}
      >
        <div className="form-item-group">
        <span className="required-marker required-marker-before">Required</span>
          <Form.Label className="required-marker required-marker-after">Name</Form.Label>
          <Form.Control
            className={`ss-input ${validationMessages.name && "ss-input-invalid"}`}
            value={modalData.name}
            type="text"
            name="name"
            required
            title={isSystemDefault ? AddEditModalDialog.CLIENT_INSTRUCTION.SYSTEM_DEFAULT_TEMPLATE_HOVER_TEXT : ""}
            disabled={isSystemDefault}
            onChange={handleOnChange}
            placeholder={AddEditModalDialog.CLIENT_INSTRUCTION.NAME_PLACEHOLDER_TEXT}
            maxLength={100}
          />
          <h6 className="text-danger">{nameValidation}</h6>
        </div>
        <div className="form-item-group">
          <Form.Label className="required-marker required-marker-after">Subject</Form.Label>
          <Form.Control
            className={`ss-input ${validationMessages.subject && "ss-input-invalid"}`}
            value={modalData.subject}
            type="text"
            name="subject"
            required
            title={isSystemDefault ? AddEditModalDialog.CLIENT_INSTRUCTION.SYSTEM_DEFAULT_TEMPLATE_HOVER_TEXT : ""}
            disabled={isSystemDefault}
            placeholder={AddEditModalDialog.CLIENT_INSTRUCTION.SUBJECT_PLACEHOLDER_TEXT}
            onChange={handleOnChange}
          />
          <h6 className="text-danger">{subjectValidation}</h6>
        </div>
        <div className="form-item-group">
          <Form.Label className="required-marker required-marker-after">Text</Form.Label>
          <div className={isSystemDefault ? "tiny-mce-wrapper-default" : "tiny-mce-wrapper"}
            title={isSystemDefault ? AddEditModalDialog.CLIENT_INSTRUCTION.SYSTEM_DEFAULT_TEMPLATE_HOVER_TEXT : ""} >
            <TinyMCEComponent
              uniquekey="client_instruction_editor"
              changeStateTinymceBody={changeMessageBody}
              messageBody={modalData.body}
              isSystemDefaultType={isSystemDefault}
            />
            <h6 className="text-danger">{textValiation}</h6>
          </div>

        </div>
        <VariablesList isClose={isClose} setIsClose={setIsClose} isSystemDefaultType={isSystemDefault} />
        <div className="settings-form-item default-checkbox">
          <Form.Check type="checkbox" checked={setDefaultMsg} onChange={setDefaultMessage} disabled={defaultMsg} />
          <Form.Label>Set as Default</Form.Label>
        </div>
      </Form>
    </CustomModal>
  );
};

export default AddEditModal;
