import React, { useEffect, useState } from "react";
import { CustomModal } from "common/components/UIComponents/CustomModal";
import { Alert, Form } from "react-bootstrap";
import { AddEditModalDialog } from "helper/Constants";
import VariablesList from "../../client-instructions/parts/VariablesList";
import { IMessageData } from "..";
import TinyMCEComponent from "common/components/tinymce/TinyMCE";
import { SavedMessageModel } from "common/model/saved-message";
import { GatherCompanySettingsModel } from "common/model/company-settings";
import { IValidationState } from "pages/settings/client-instructions/parts/AddEditModal";
import { AppNotifier } from "common/components/toast/Toast";
export enum ModalType {
  NONE,
  ADD,
  EDIT
}
interface ISavedMessageModal {
  show: boolean;
  onSubmit: (data: SavedMessageModel, setDefault: boolean) => void;
  onHide: () => void;
  modalType: ModalType;
  initialData: SavedMessageModel;
  companySettings: GatherCompanySettingsModel;
  data: SavedMessageModel[];
}
export const initialValidationMessages = {
  name: "",
  text: ""
};

const SavedMessageModal: React.FC<ISavedMessageModal> = ({
  show,
  onHide,
  onSubmit,
  modalType,
  initialData,
  companySettings,
  data
}) => {
  const ref = React.useRef<HTMLFormElement>(null);
  const defaultId = initialData.id === companySettings.defaultSettings.savedMessage;
  const [modalData, setModalData] = useState<SavedMessageModel>(initialData);
  const [namevalidation, setNamevalidation] = useState("");
  const [textvalidation, setTextvalidation] = useState("");
  const [setDefault, onSetDefault] = useState(defaultId);
  const [isClose, setIsClose] = React.useState(true);
  const isSystemDefaultMessage = modalData.isSystemDefault;
  const [validated, setValidated] = React.useState<boolean>(false);
  useEffect(() => {
    setModalData(initialData);
    onSetDefault(defaultId);
  }, [initialData]);

  const onAllow = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModalData({ ...modalData, isEditable: event.target.checked });
  };

  const onSetDefaultChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSetDefault(event.target.checked);
    event.target.checked === true
      ? AppNotifier.Warning(AddEditModalDialog.CLIENT_INSTRUCTION.SetDefaultMessageWarning)
      : AppNotifier.Warning(AddEditModalDialog.CLIENT_INSTRUCTION.RemoveDefaultMessageWarning);
  };

  const checkDuplicate = (messageDetail: SavedMessageModel) => {
    let count: number = 0;
    const emailMessages = data;
    emailMessages != null &&
      emailMessages.map((message, index) => {
        if (message.name.trim().toLowerCase() === messageDetail.name.trim().toLowerCase() && message.id !== messageDetail.id)
          count++;
      });
    return count;
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.trimStart();
    if (input) {
      setNamevalidation("");
    } else {
      setNamevalidation(AddEditModalDialog.CLIENT_INSTRUCTION.ValidateName)
      setValidated(false);
    }
    setModalData({ ...modalData, name: input });
  };

  const isModalDataValid = () => {
    let isValid = true;
    if (checkDuplicate(modalData) > 0) {
      AppNotifier.Warning(AddEditModalDialog.CLIENT_INSTRUCTION.DUPLICATE_INSTRUCTION_NAME);
      isValid = false;
    }
    if (modalData.name.trim().length === 0) {
      setNamevalidation(AddEditModalDialog.CLIENT_INSTRUCTION.ValidateName)
      isValid = false;
    } else if (modalData.text.trim().length === 0) {
      setTextvalidation(AddEditModalDialog.CLIENT_INSTRUCTION.ValidateBody);
      isValid = false;
    }
    return isValid;
  };

  const onCancel = () => {
    setValidated(false)
    onHide();
  };

  const handleOnSubmit = () => {
    const form = ref.current;
    setValidated(false)
    if (!!form && isModalDataValid()) {
      onSubmit(modalData, setDefault);
    }
  };

  const changeMessageBody = (e: any) => {
    if (e) {
      setTextvalidation("");
    } else {
      setTextvalidation(AddEditModalDialog.CLIENT_INSTRUCTION.ValidateBody);
      setValidated(false);
    }
    setModalData({ ...modalData, text: e });
  };

  return (
    <CustomModal
      show={show}
      onSubmit={handleOnSubmit}
      onHide={onCancel}
      title={modalType === ModalType.ADD ? AddEditModalDialog.TP_MESSAGE.ADD_TITLE : AddEditModalDialog.TP_MESSAGE.EDIT_TITLE}
      confirmButtonName={
        modalType === ModalType.ADD
          ? AddEditModalDialog.CLIENT_INSTRUCTION.ADD_BUTTON_TEXT
          : AddEditModalDialog.CLIENT_INSTRUCTION.UPDATE_BUTTON_TEXT
      }
      onCancelButtonClick={onCancel}
      className="add-edit-modal"
      confirmDisabled={validated}
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleOnSubmit();
        }}
        ref={ref}
        noValidate
        validated={textvalidation !== "" && namevalidation !== ""}
      >
        <div className="form-item-group">
        <span className="required-marker required-marker-before">Required</span>
          <Form.Label className="required-marker required-marker-after">Name</Form.Label>
          <Form.Control
            className="ss-input"
            value={modalData?.name}
            type="text"
            name="name"
            onChange={handleOnChange}
            title={isSystemDefaultMessage ? AddEditModalDialog.CLIENT_INSTRUCTION.SYSTEM_DEFAULT_TEMPLATE_HOVER_TEXT : ""}
            disabled={isSystemDefaultMessage}
            placeholder={AddEditModalDialog.TP_MESSAGE.NAME_PLACEHOLDER_TEXT}
            required={true}
            maxLength={100}
          />
          <h6 className="text-danger">{namevalidation}</h6>
        </div>
        <div className="form-item-group">
          <Form.Label className="required-marker required-marker-after">Text</Form.Label>
          <div className={isSystemDefaultMessage ? "tiny-mce-wrapper-default" : "tiny-mce-wrapper"}
            title={isSystemDefaultMessage ? AddEditModalDialog.CLIENT_INSTRUCTION.SYSTEM_DEFAULT_TEMPLATE_HOVER_TEXT : ""}>
            <TinyMCEComponent
              uniquekey="ada"
              changeStateTinymceBody={changeMessageBody}
              messageBody={modalData.text}
              isSystemDefaultType={isSystemDefaultMessage}
            />
            <h6 className="text-danger">{textvalidation}</h6>
          </div>
        </div>
        <div className="settings-form-item user-checkbox" title={isSystemDefaultMessage ? AddEditModalDialog.CLIENT_INSTRUCTION.SYSTEM_DEFAULT_TEMPLATE_HOVER_TEXT : ""}>
          <Form.Check 
            type="checkbox" checked={modalData.isEditable}
            onChange={onAllow}
            disabled={isSystemDefaultMessage}
          />
          <Form.Label>Allow users to edit before sending</Form.Label>
        </div>
        <VariablesList isClose={isClose} setIsClose={setIsClose} isSystemDefaultType={isSystemDefaultMessage} />
        <div className="settings-form-item default-checkbox">
          <Form.Check type="checkbox" checked={setDefault} onChange={onSetDefaultChange} />
          <Form.Label>Set as Default</Form.Label>
        </div>
      </Form>
    </CustomModal>
  );
};

export default SavedMessageModal;
