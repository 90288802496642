import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICompany, IOfficeLocation, ITaxSoftwareSettings, initialCompanyProfile } from "common/model/company";

const companySlice = createSlice({
    name: 'company',
    initialState: initialCompanyProfile as ICompany,
    reducers: {
        setCompanyOfficeLocations(state, action: PayloadAction<IOfficeLocation[]>) {
            state.officeLocations = action.payload;
        },
        setCompanyProfile(state, action: PayloadAction<ICompany>) {
            state.companyInfo = action.payload.companyInfo;
            state.countryStates = action.payload.countryStates;
            state.companyProducts=action.payload.companyProducts;
            state.companySubscription = action.payload.companySubscription;
            state.isLoading=false;
        },
        setCompanySignatureLink(state, action: PayloadAction<string>){
            state.signatureUploadLink = action.payload;
        },
        setCommonSettings(state, action: PayloadAction<ITaxSoftwareSettings>) {
            state.taxSoftwareSetting = action.payload;
        },
        setCompanyLogoSetting(state, action: PayloadAction<{logoPath: string;
            isSsrLogo: boolean}>) {
          state.companyLogoSetting.isSsrLogo = action.payload.isSsrLogo;
          state.companyLogoSetting.logoPath = action.payload.logoPath;
        },
        setIsTestCompany(state, action: PayloadAction<boolean>) {
            state.isTestCompany = action.payload;
        },
    }
})
export const { setCompanyProfile, setCompanyOfficeLocations, setCommonSettings ,setCompanySignatureLink,setCompanyLogoSetting,setIsTestCompany} = companySlice.actions;
export const { reducer: companyReducer } = companySlice;
