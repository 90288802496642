import { EngagementCategory } from "./engagement-category";
export interface EngagementsType {
    id: number,
    name: string,
    isSystemDefault: boolean,
    isUserDefault: boolean,
    engagementCategoryId: number;
}
export interface EngagementsTypeDetails {
    id: number,
    name: string,
    isSystemDefault: boolean,
    isUserDefault: boolean,
    engagementCategoryId: number;
    engagementCategoryName: string;
}

export interface EngagementsTypeState {
    engagementsTypeArray: EngagementsType[];
    engagementCategories: EngagementCategory[];
}

export enum EngagementCategoryData {
    Individual = 1,
    Entity = 2
}