import { ProductType } from "@sssuite-component-ui/ss-suite-layout"
import { ICompanyLogoSetting } from "./company-settings";
import { TaxSoftware } from "common/enum/GatherEnums"

export interface ICompany {
    isLoading: boolean,
    companyInfo: ICompanyInfo,
    companyProducts:ICompanyProduct[],
    countryStates: ICountryState[],
    signatureUploadLink: string
    companyLogoSetting: ICompanyLogoSetting;
    officeLocations: IOfficeLocation[]
    taxSoftwareSetting: ITaxSoftwareSettings
    isTestCompany: boolean
    companySubscription: ICompanySubscription
}

export interface ICountryState {
    id: number
    name: string
    image?: string
  }

export interface ICompanySubscription {
    id: number
    name: string
  }

export interface ICompanyInfo {
    id: number
    companyName: string
    adminUser: number
    companyGuid: string
  }

  export interface ICompanyProduct {
    productId: ProductType;
    productStatus: ProductStatus;
    isSubscribed: boolean;
    isProduct: boolean;
  }

  export enum ProductStatus{
    None = 0,
    Enabled,
    Disabled
  }

export interface IOfficeLocation {
    locationId: number,
    isPrimary: boolean,
    name: string,
    countryCode: string,
    phone: string,
    address1: string,
    address2: string,
    city: string,
    zip: string,
    fax: string,
    website: string,
    state: number,
}
export const initialCompanyLogoSettings: ICompanyLogoSetting = {
  isSsrLogo: false,
  logoPath: "",
  updatedLogo: new Blob([]),
  isLogoUpdated: false,
  isLogoChangedToMyLogo: false,
  isCompanyNameChanged: false
}
export interface ITaxSoftwareSettings {
  taxSoftware: TaxSoftware[];
}
const intialTaxSoftwareSetting: ITaxSoftwareSettings={
  taxSoftware:[]
}
export const initialCompanyProfile: ICompany = {
    officeLocations: [],
    isLoading: true,
    countryStates: [],
    companyProducts:[],
    companyInfo:
    {
        id: 0,
        companyGuid:'',
        companyName:'',
        adminUser:0
    },
    signatureUploadLink: '',
    companyLogoSetting: initialCompanyLogoSettings,
    taxSoftwareSetting:intialTaxSoftwareSetting,
    isTestCompany:true,
    companySubscription:
    {
      id:0,
      name:''
    }
}
